<template>
  <div>

    <div class="d-flex justify-content-between">

      <field-select
          :name="'article'+ (listIndex != -1?(' '+(listIndex+1)):'')"
          :displayLabel="displayLabel"
          :model.sync="articleLocal"
          :options="articles"
          :label="'name'"
          :isRequired="true"
          :allowAdd="true"
          :addText="'AddArticle'"
          @addCallback="selectArticle"
          class="w-100"
          :key="componentKey"
          :disabled="disabled"
      >
      </field-select>

      <div class="ml-1">
        <button-external-link
            :withBorder="false"
            @click="selectArticle"
            :disabled="!canEditArticle || disabled"
        />
      </div>

    </div>

    <!-- modal article-->
    <modal-article
        :article.sync="currentArticle"
        :title="('id' in currentArticle)?$t('EditArticle'):$t('NewArticle')"
        :isOpen.sync="articleModalShow"
        @submitValidatedArticle="submitValidatedArticleLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { capitalize } from '@/utils/filter'
import { isObject } from '@/utils/utils'

import ModalArticle from '@/components/prompt/Article'
import ArticleForm from '@/components/form/Article'
import FieldSelect from '@/components/input/Select'
import useAPI from '@/utils/useAPI'
import store from '@/store'
import ButtonExternalLink from '../button/ExternalLink'

export default {
  components: {
    ModalArticle,
    ArticleForm,
    ValidationObserver,
    FieldSelect,
    ButtonExternalLink
  },
  props: {
    displayLabel: {
      type: Boolean,
      default: true
    },
    listIndex: {
      type: Number,
      default: -1
    },
    article: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const articleLocal = ref(null)
    const articleModalShow = ref(false)
    const submitArticleFormRef = ref(null)
    const currentArticle = ref({})
    const componentKey = ref(0)

    // const selectedArticle = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { articles } = useAPI()

    const canEditArticle = computed(() => {
      return articleLocal.value != null
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(articleLocal, (val) => {
      if (val != null) {
        emit('update:article', articles.value.find(art => art.id == (isObject(val) ? val.id : val)))
      }
    })

    watch(articleModalShow, () => {
      if (articleModalShow.value == false) {
        resetArticle()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseArticleModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitArticleFormRef.value.click()
      }
    }

    const resetArticle = () => {
      currentArticle.value = JSON.parse(JSON.stringify({}))
    }

    const selectArticle = () => {
      // if (!props.disabled && canEditArticle.value) {
      //   currentArticle.value = JSON.parse(JSON.stringify(
      //       articles.value.find(art => art.id == (isObject(articleLocal.value) ? articleLocal.value.id : articleLocal.value))
      //   ))
      //   articleModalShow.value = true
      // }

      if (!props.disabled && canEditArticle.value) {
        if (articleLocal.value != null) {
            currentArticle.value = JSON.parse(JSON.stringify(
                articles.value.find(art => art.id == (isObject(articleLocal.value) ? articleLocal.value.id : articleLocal.value))
            ))
        } else {
          resetArticle()
        }
        articleModalShow.value = true
      } else if (articleLocal.value == null) {
        // New
        articleModalShow.value = true
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------
    onMounted(() => {
      if (props.article != null) {
        articleLocal.value = JSON.parse(JSON.stringify(props.article))
        componentKey.value++
      }
    })

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetArticle()

    return {
      // Components
      capitalize,

      // Data
      articleLocal,
      articleModalShow,
      submitArticleFormRef,
      currentArticle,
      componentKey,

      // Computed
      articles,
      canEditArticle,

      // Methods
      tryCloseArticleModal,
      selectArticle
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    submitValidatedArticleLocal () {
      // console.log(this.currentArticle)

      if ('id' in this.currentArticle) {
        // Edit
        this.$store.dispatch('article/updateArticle', this.currentArticle)
            .then(response => {
              this.$emit('update:article', response.data)
              this.$nextTick(() => this.articleModalShow = false)
            })
            .catch(error => {
              console.log(error)
            })
      } else {
        // New
        this.$store.dispatch('article/addArticle', this.currentArticle)
            .then(response => {
              this.articleLocal = response.data
              this.componentKey++

              this.articleModalShow = false
            })
            .catch(error => {
              console.log(error)
            })
      }

    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>