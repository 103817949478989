<template>
    <b-card
        v-if="$route.name == 'Offer edit'"
    >
      <!-- Button: Edit -->
      <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-success"
          class="mb-75"
          block
          @click="$emit('submitForm')"
      >
        {{ $t('Edit') }}
      </b-button>
    </b-card>
    <b-card
        v-else-if="offer.isCanceled == true"
    >
      <!--          Archivate -->
      <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          @click="$emit('archivateOffer', true)"
          variant="outline-warning"
          class="mb-0"
          block
      >
        {{ capitalize($t('Archivate')) }}
      </b-button>
    </b-card>
    <b-card
        v-else-if="offer.isArchived == true"
    >
      <!-- Button: Unarchive -->
      <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-success"
          class="mb-75"
          block
          @click="$emit('archivateOffer', false)"
      >
        {{ $t('Unarchive') }}
      </b-button>
    </b-card>
    <b-card
        v-else-if="offer.isDeclined == true"
    >
      <!-- Button: Edit -->
      <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-success"
          class="mb-75"
          block
          @click="$emit('declinedOffer', false)"
      >
        {{ $t('Resume') }}
      </b-button>
    </b-card>

    <b-card v-else>

      <!-- Button: Validate offer -->
      <b-button
          v-if="offer._isValidated == false"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mb-75"
          block
          @click="$emit('validateOffer')"
      >
        {{ $t('ValidatedOffer') }}
      </b-button>

      <!-- Button: Decline offer -->
      <b-button
          v-if="offer._isValidated == false && offer.isDeclined != true"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          class="mb-75"
          block
          @click="$emit('declinedOffer', true)"
      >
        {{ $t('DeclinedOffer') }}
      </b-button>

      <!-- Button: Edit -->
      <b-button
          v-if="offer._isValidated == false"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-warning"
          class="mb-75"
          block
          :to="{ name: 'Offer edit', params: { offerId: $route.params.offerId } }"
      >
        {{ $t('Edit') }}
      </b-button>

      <!-- Button: Transform to invoice -->
      <b-button
          v-if="offer._isValidated == true && isSelectableProducts==true"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mb-4"
          block
          @click="$emit('transformProductsToOutgoingInvoice')"
      >
        {{ $t('transformOfferToOutgoingInvoice') }}
      </b-button>

      <!-- Button: Cancel -->
      <b-button
          v-if="offer._isValidated == true"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          class="mb-4"
          block
          @click="$emit('cancelOffer')"
      >
        {{ $t('cancelOffer') }}
      </b-button>

      <!--          Duplicate -->
      <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          @click="$emit('duplicateOffer')"
          variant="outline-secondary"
          class="mb-0"
          block
      >
        {{ capitalize($t('duplicate')) }}
      </b-button>

      <!--          Archivate -->
      <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          @click="$emit('archivateOffer', true)"
          variant="outline-warning"
          class="mb-0"
          block
      >
        {{ capitalize($t('Archivate')) }}
      </b-button>


      <!--          Delete -->
      <b-button
          v-if="offer.billNumber == null"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          @click="$emit('removeOffer')"
          variant="outline-danger"
          class="mb-50"
          block
      >
        {{ capitalize($t('delete')) }}
      </b-button>

    </b-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'

import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
  },
  props: {
    offer: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    const isSelectableProducts = computed(() => {
      return props.offer.products.filter(p => p.outgoingInvoice == null).length != 0
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed
      isSelectableProducts,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () { },
  created () { }
}
</script>

<style lang="scss">

</style>