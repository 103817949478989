<template>
  <div>

    <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="btn-icon mb-2"
        variant="outline-primary"
        @click="addressModalShow=true"
    >
      <icon icon="plus"/>
    </b-button>

    <b-row>
      <b-col
          v-for="(address, index) in addresses"
          :key="index"
          cols="6"
      >
        <address-card
            :address="address"
            @click="selectAddress(address, index)"
            @deleteAddress="deleteAddress(index)"
        />
      </b-col>
    </b-row>

    <b-modal
        :title="addressModalTitle"
        v-model="addressModalShow"
        size="lg"
        scrollable
        hide-footer
        @hide="tryCloseAddressModal"
    >
      <validation-observer #default="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(addressFormValidated)">
          <address-form :address="currentAddress"
                        :siren="siren"
                        :displaySiren="displaySiren"/>
          <button ref="submitAddressForm"
                  class="d-none"></button>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { ref, computed, watch } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import i18n from '@/libs/i18n'
import AddressForm from '@/components/form/Address'
import AddressCard from '@/components/card/Address'

export default {
  directives: {
    Ripple
  },
  components: {
    ValidationObserver,
    AddressForm,
    AddressCard
  },
  props: {
    addresses: {
      type: Array,
      default: () => []
    },
    siren: {
      type: String,
      default: ''
    },
    displaySiren: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const addressModalShow = ref(false)
    const addressModalTitle = ref(i18n.t('NewAddress'))
    const currentAddress = ref({})
    const selectedAddressInit = ref(null)
    const selectedAddressIndex = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(addressModalShow, () => {
      if (addressModalShow.value == false) {
        if (selectedAddressInit.value != null) {
          // Undo changes
          props.addresses.splice(selectedAddressIndex.value, 1, selectedAddressInit.value)
        }

        resetAddress()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const resetAddress = () => {
      currentAddress.value = JSON.parse(JSON.stringify({
        country: 'FR'
      }))
      selectedAddressInit.value = JSON.parse(JSON.stringify(null))
      selectedAddressIndex.value = JSON.parse(JSON.stringify(null))
    }

    const selectAddress = (address, addressIndex) => {
      selectedAddressInit.value = JSON.parse(JSON.stringify(address))
      selectedAddressIndex.value = JSON.parse(JSON.stringify(addressIndex))

      currentAddress.value = address
      addressModalShow.value = true
    }

    const hasSameAddressWithoutId = (address) => {
      return props.addresses.filter(ad => {
        return !('id' in ad) &&
            address.nic == ad.nic &&
            address.isBillingAddress == ad.isBillingAddress &&
            address.country == ad.country &&
            address.street == ad.street &&
            address.zipCode == ad.zipCode &&
            address.city == ad.city
      }).length
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetAddress()

    return {
      addressModalShow,
      addressModalTitle,
      currentAddress,
      selectedAddressInit,
      selectAddress,
      hasSameAddressWithoutId
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    addressFormValidated () {
      // console.log('Address form validated !')
      // console.log(this.currentAddress)

      if (!('id' in this.currentAddress)) {
        if (this.hasSameAddressWithoutId(this.currentAddress) == 0) {
          // New address (and not edit not saved address)
          this.addresses.splice(this.addresses.length, 0, this.currentAddress)
        }
        this.selectedAddressInit = JSON.parse(JSON.stringify(null))

        this.$nextTick(() => this.addressModalShow = false)
      } else {
        this.selectedAddressInit = JSON.parse(JSON.stringify(null))

        this.$nextTick(() => {this.addressModalShow = false})
      }
    },
    tryCloseAddressModal (modalEvent) {
      if (modalEvent.trigger == 'backdrop') {
        modalEvent.preventDefault()
        this.$refs.submitAddressForm.click()
      }
    },
    deleteAddress(index) {
      this.addresses.splice(index, 1)
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>