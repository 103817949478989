import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { useOffers } from './useOffers'
import { capitalize, currency } from '@/utils/filter'
import { getRoute } from '@/utils/utils'

import i18n from '@/libs/i18n'
import store from '@/store'
import router from '@/router'
import useAPI from '@/utils/useAPI'

import ModalOffer from '@/components/prompt/Offer'
import ModalOfferValidation from '@/components/prompt/OfferValidation'

export const mixinOffers = {
  components: {
    ModalOffer,
    ModalOfferValidation
  },
  props: {},
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const offerModalShow = ref(false)
    const offerModalTitle = ref(i18n.t('NewOffer'))
    const currentOffer = ref({})
    const isOfferValidationModalOpen = ref(false)
    const submitOfferFormRef = ref(null)
    const submitOfferAcceptanceFormRef = ref(null)
    const selectedProducts = ref([])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(offerModalShow, () => {
      if (offerModalShow.value == false) {
        resetOffer()
      }
    })

    watch(currentOffer, (val) => {
      if (getRoute().name == 'Workflow view') {
        let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)

        currentOffer.value.workflow = {
          id: workflow.id,
          opportunity: workflow.opportunity
        }

        if (
          !('id' in currentOffer.value) &&
          currentOffer.value.customerCompany == null &&
          currentOffer.value.customerIndividual == null
        ) {
          currentOffer.value.customerCompany = workflow.customerCompany
          currentOffer.value.customerIndividual = workflow.customerIndividual

          if (workflow.customerCompany != null && workflow.customerCompany.addresses.length) {
            currentOffer.value.billingAddress = workflow.customerCompany.addresses[0]
            currentOffer.value.deliveryAddress = workflow.customerCompany.addresses[0]
          }
        }
      }

    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchArticles,
      fetchUsers,
      fetchCompanies,
      fetchOffers,
      fetchPaymentMethods,
      fetchPaymentTerms,
      fetchVats,
      fetchWorkflows
    } = useAPI()

    const populateWorkflow = (offer) => {
      if (getRoute().name == 'Workflow view') {
        let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)

        offer.workflow = {
          id: workflow.id,
          opportunity: workflow.opportunity
        }
      }
    }

    const updateWorkflow = (updatedOffer) => {
      if (getRoute().name == 'Workflow view') {
        let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)

        if ('id' in updatedOffer) {
          let index = workflow.offers.findIndex(o => o.id == updatedOffer.id)
          workflow.offers.splice(index, 1, JSON.parse(JSON.stringify(updatedOffer)))
        } else if (updatedOffer != null) {
          workflow.offers.push(updatedOffer)
        } else {
          workflow.offers.splice(index, 1)
        }
      }
    }

    const {
      submitValidatedOffer,
      removeOffer,
      validateOffer,
      transformProductsToOutgoingInvoice,
      downloadOfferQuote
    } = useOffers()

    const addOffer = () => {
      offerModalShow.value = true
    }

    const selectOffer = (offer) => {
      offerModalTitle.value = i18n.t('EditOffer')
      currentOffer.value = JSON.parse(JSON.stringify(offer))
      populateWorkflow(currentOffer.value)

      offerModalShow.value = true
    }

    const submitValidatedOfferLocal = () => {
      submitValidatedOffer(currentOffer.value)
        .then(response => {
          offerModalShow.value = false
          updateWorkflow(response.data)

          if (getRoute().name == 'Workflow view') {
            let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
            // delete response.data.workflow
            response.data.workflow = response.data.workflow.id

            if ('id' in currentOffer.value) {
              let index = workflow.offers.findIndex(i => i.id == currentOffer.value.id)
              workflow.offers.splice(index, 1, JSON.parse(JSON.stringify(response.data)))
            } else {
              workflow.offers.push(response.data)
            }

          }
        })
    }

    const resetOffer = () => {
      currentOffer.value = JSON.parse(JSON.stringify(store.getters['offer/getEmptyOffer']))
    }

    const validateOfferLocal = (offer) => {
      currentOffer.value = JSON.parse(JSON.stringify(offer))
      populateWorkflow(currentOffer.value)
      currentOffer.value.signedDocumentDate = (new Date()).toISOString().slice(0, 10)
      isOfferValidationModalOpen.value = true
    }

    const declinedOfferLocal = (offer, isDeclined) => {
      currentOffer.value = JSON.parse(JSON.stringify(offer))
      populateWorkflow(currentOffer.value)
      currentOffer.value.isDeclined = isDeclined

      submitValidatedOffer(currentOffer.value)
        .then(response => {
          updateWorkflow(response.data)
          resetOffer()
        })
    }

    const archivateOfferLocal = (offer, isArchived) => {
      currentOffer.value = JSON.parse(JSON.stringify(offer))
      populateWorkflow(currentOffer.value)
      currentOffer.value.isArchived = isArchived

      submitValidatedOffer(currentOffer.value)
        .then(response => {
          updateWorkflow(response.data)

          resetOffer()
        })
    }

    const submitValidatedOfferValidationForm = () => {
      submitValidatedOffer(currentOffer.value)
        .then(response => {
          updateWorkflow(response.data)
          resetOffer()
          isOfferValidationModalOpen.value = false
        })
    }

    const transformProductsToOutgoingInvoiceLocal = (offer) => {
      if (selectedProducts.value.length == 0) {
        selectedProducts.value = offer.products.filter(p => p.outgoingInvoice == null)
      }

      transformProductsToOutgoingInvoice(offer, selectedProducts.value)
        .then(response => {
          updateWorkflow(response.data)
          selectedProducts.value = []
        })

    }

    const duplicateOffer = (offer) => {
      currentOffer.value = JSON.parse(JSON.stringify(offer))
      populateWorkflow(currentOffer.value)
      delete currentOffer.value.billNumber
      delete currentOffer.value.signedDocument
      delete currentOffer.value.signedDocumentNumber
      delete currentOffer.value.signedDocumentDate
      delete currentOffer.value.id
      delete currentOffer.value.logs

      offerModalShow.value = true
    }

    const removeOfferLocal = (offer) => {
      removeOffer(offer)
        .then(response => {
          if (getRoute().name == 'Offer view') {
            router.push({ name: 'Offers' })
          } else if (getRoute().name == 'Workflow view') {
            // updateWorkflow(offer)
            let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
            let index = workflow.offers.findIndex(o => o.id == offer.id)
            workflow.offers.splice(index, 1)

            // let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
            // workflow.offers = workflow.offers.filter(ii => ii.id != offer.id)
          }

        })
    }

    const cancelOfferLocal = (offer) => {
      currentOffer.value = JSON.parse(JSON.stringify(offer))
      populateWorkflow(currentOffer.value)
      currentOffer.value.isCanceled = true

      submitValidatedOffer(currentOffer.value)
        .then(response => {
          updateWorkflow(response.data)
          resetOffer()
        })
    }

    const getPreTaxAmounts = (offers) => {
      let preTaxAmount = 0
      offers.forEach(q => preTaxAmount += q.preTaxAmount)

      return preTaxAmount
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (getRoute().name.startsWith('Offer')) {
      fetchArticles()
      fetchUsers()
      fetchCompanies()
      fetchOffers()
      fetchPaymentMethods()
      fetchPaymentTerms()
      fetchVats()
      fetchWorkflows()
    }

    resetOffer()

    return {
      // Components
      capitalize,
      currency,

      // Data
      offerModalShow,
      offerModalTitle,
      currentOffer,
      isOfferValidationModalOpen,
      submitOfferFormRef,
      submitOfferAcceptanceFormRef,
      selectedProducts,

      // Computed

      // Methods
      addOffer,
      selectOffer,
      // tryCloseOfferModal,
      submitValidatedOfferLocal,
      // tryCloseOfferAcceptanceModal,
      // submitValidatedTransformOfferToOutgoingInvoiceForm,
      downloadOfferQuote,
      removeOffer,
      validateOfferLocal,
      declinedOfferLocal,
      archivateOfferLocal,
      submitValidatedOfferValidationForm,
      transformProductsToOutgoingInvoiceLocal,
      // submitValidatedOfferValidationForm,
      // openModalTransformtoOutgoingInvoice,
      duplicateOffer,
      removeOfferLocal,
      cancelOfferLocal,
      getPreTaxAmounts
    }
  },
  data () {
    return {}
  },
  methods: {
    deleteOfferAlert (offer) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theOffer') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeOfferLocal(offer)
          }
        })
    },
    cancelOfferAlert (offer) {
      this.$bvModal
        .msgBoxConfirm(this.$t('CancelAlert', { msg: this.$t('theOffer') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('cancel') + ' ' + this.$t('theOffer')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.cancelOfferLocal(offer)
          }
        })
    }
  },
}

export const _ = null
