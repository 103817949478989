<template>
  <section class="invoice-preview-wrapper">

    <b-row
        v-if="isReady"
        class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
          cols="12"
          xl="9"
          md="8"
      >

        <view-offer
            :offer="offer"
            :selectedProducts.sync="selectedProducts"
        />
      </b-col>

      <!-- Right Col: Card -->
      <b-col
          cols="12"
          md="4"
          xl="3"
          class="offer-actions"
      >
        <buttons-col
            :offer="offer"
            @validateOffer="validateOfferLocal(offer)"
            @declinedOffer="declinedOfferLocal(offer, $event)"
            @archivateOffer="archivateOfferLocal(offer, $event)"
            @duplicateOffer="duplicateOffer(offer)"
            @removeOffer="deleteOfferAlert(offer)"
            @cancelOffer="cancelOfferAlert(offer)"
            @downloadOfferQuote="downloadOfferQuote(offer)"
            @transformProductsToOutgoingInvoice="transformProductsToOutgoingInvoiceLocal(offer)"
        />

        <documents-col
            :offer="offer"
            @downloadOfferQuote="downloadOfferQuote(offer)"
        />
      </b-col>

    </b-row>


    <!-- modal offer-->
    <modal-offer
        :offer.sync="currentOffer"
        :title="offerModalTitle"
        :isOpen.sync="offerModalShow"
        @submitValidatedOffer="submitValidatedOfferLocal"
    />

    <!-- modal offer validation-->
    <modal-offer-validation
        :offer.sync="currentOffer"
        :isOpen.sync="isOfferValidationModalOpen"
        @submitValidatedOfferValidationForm="submitValidatedOfferValidationForm"
    />

  </section>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { isObject } from '@/utils/utils'

import { mixinOffers } from './mixinOffers'
import store from '@/store'
import router from '@/router'
import ViewOffer from '@/components/views/Offer'
import ButtonsCol from './components/_buttons_col'
import DocumentsCol from './components/_documents_col'

export default {
  components: {
    ViewOffer,
    ButtonsCol,
    DocumentsCol,
  },
  mixins: [mixinOffers],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    // const selectedProducts = ref([])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const offer = computed(() => {
      return store.getters['offer/getOffer'](router.history.current.params.offerId)
    })

    const isReady = computed(() => (offer.value &&
        isObject(offer.value.billingAddress) &&
        isObject(offer.value.deliveryAddress)))

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      // selectedProducts,

      // Computed
      offer,
      isReady,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    offer (val) {
      this.currentOffer = JSON.parse(JSON.stringify(val))
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>