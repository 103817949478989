<template>
  <form-offer-validation
      :offer="offer"
      :listDisplay="true"
  />
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import FormOfferValidation from '@/components/form/OfferValidation'

export default {
  components: {
    FormOfferValidation
  },
  props: {
    offer: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>