<template>
  <b-modal
      id="1"
      :title="title"
      v-model="isOpenLocal"
      size="lg"
      scrollable
      hide-footer
      @hide="tryCloseOfferModal"
  >
    <validation-observer #default="{handleSubmit, errors}">
      <b-form @submit.prevent="handleSubmit(submitValidatedOfferLocal)">
        <form-offer :offer="offerLocal"
                    :formErrors="errors"/>
        <button ref="submitOfferFormRef"
                class="d-none"></button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'

import FormOffer from '@/components/form/offer/Offer'

export default {
  components: {
    ValidationObserver,
    FormOffer
  },
  props: {
    offer: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const offerLocal = ref(props.offer)
    const submitOfferFormRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(offerLocal, (val) => {
      emit('update:offer', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseOfferModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitOfferFormRef.value.click()
      }
    }

    const submitValidatedOfferLocal = () => {
      emit('submitValidatedOffer')
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isOpenLocal,
      offerLocal,
      submitOfferFormRef,

      // Computed

      // Methods
      tryCloseOfferModal,
      submitValidatedOfferLocal
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    offer: function (val) {
      this.offerLocal = val
    },
    isOpen: function (val) {
      this.isOpenLocal = val
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>