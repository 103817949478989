<template>
  <b-row>
    <b-col :md="offer.workflow==null?12:6">
      <field-select
          :model.sync="offer.workflow"
          :name="'workflow'"
          :options="workflows"
          :label="'opportunity'"
          class="w-100"
          @input="resetOfferPhase"
      />
    </b-col>

    <b-col md="6"
           v-if="offer.workflow">
      <field-select
          :model.sync="offer.phase"
          :name="'phase'"
          :placeholder="$t('Choose a phase')"
          :options="availablePhases"
          :label="'name'"
          class="w-100"
          :key="componentKey"
      />
    </b-col>
  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { isObject } from '../../../../utils/utils'

import useAPI from '@/utils/useAPI'
import FieldSelect from '@/components/input/Select'

export default {
  components: {
    FieldSelect
  },
  props: {
    offer: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { workflows } = useAPI()

    const availablePhases = computed(() => {
      if (isObject(props.offer.workflow)) {
        return workflows.value.find(w => w.id == props.offer.workflow.id).phases
      } else if (workflows.value.some(w => w.id == props.offer.workflow)) {
        return workflows.value.find(w => w.id == props.offer.workflow).phases
      } else {
        return []
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const resetOfferPhase = () => {
      props.offer.phase = null
      componentKey.value++
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      componentKey,

      // Computed
      workflows,
      availablePhases,

      // Methods
      resetOfferPhase,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>