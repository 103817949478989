<template>
  <div>

    <b-row>

      <!--          Name-->
      <b-col cols="12">
        <field
            :name="'name'"
            :rules="'required'"
            :model.sync="company.name"
        />
      </b-col>

      <!--          Phone-->
      <b-col cols="6">
        <field
            :name="'phone'"
            :rules="'phone'"
            :model.sync="company.phone"
        />
      </b-col>

      <!--          SIREN-->
      <b-col cols="6">
        <field
            :name="'siren'"
            :model.sync="company.siren"
        />
      </b-col>

      <!--          VAT number-->
      <b-col cols="6">
        <field
            :name="'vatNumber'"
            :model.sync="company.vat"
        />
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="12">
        <b-tabs fill>

          <!--          Tab employees-->
          <b-tab active>
            <template #title>
              <icon icon="users"/>
              <span>{{ capitalize($tc('employee', 2)) }} ({{ company.employees.length }})</span>
            </template>

            <company-employees
                :employees="company.employees"
            />
          </b-tab>

          <!--          Tab addresses-->
          <b-tab>
            <template #title>
              <icon icon="map-marked-alt"/>
              <span>{{ capitalize($tc('address', 2)) }} ({{ company.addresses.length }})</span>
            </template>

            <tab-addresses
                :addresses="company.addresses"
                :siren="company.siren"
            />
          </b-tab>

        </b-tabs>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import { required } from '@/utils/validations/validations'
import { capitalize } from '@/utils/filter'

import Field from '@/components/input/Input'
import FieldSwitch from '@/components/input/Switch'
import CompanyEmployees from './components/_tab_employees'
import TabAddresses from './components/_tab_addresses'

export default {
  components: {
    Field,
    FieldSwitch,
    ValidationProvider,
    CompanyEmployees,
    TabAddresses
  },
  props: {
    company: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      capitalize,
      required
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>