<template>
  <b-row>

    <!--          Name-->
    <b-col md="8">
      <field
          :name="'name'"
          :rules="'required'"
          :model.sync="article.name"
      />
    </b-col>

    <!--          Supplier reference-->
    <b-col md="4">
      <field
          :name="'supplierReference'"
          :model.sync="article.supplierReference"
      />
    </b-col>

    <!--        Unit price-->
    <b-col md="6">
      <field
          :name="'unitPrice'"
          :model.sync="article.unitPrice"
      />
    </b-col>

    <!--        VAT-->
    <b-col md="6">
      <field-select
          :name="'VAT'"
          :model.sync="article.vatRate"
          :options="vats"
          :label="'name'"
          :placeholder="$t('Choose an VAT')"
          :clearable="false"
      />
    </b-col>

    <!--        Description-->
    <b-col cols="12">
      <field-textarea
          :model.sync="article.description"
          :name="'description'"
          :placeholder="'description'"
      />
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import Field from '@/components/input/Input'
import FieldSelect from '@/components/input/Select'
import FieldTextarea from '@/components/input/Textarea'
import useAPI from '@/utils/useAPI'

export default {
  components: {
    Field,
    FieldSelect,
    FieldTextarea,
  },
  props: {
    article: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { vats } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      vats,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>