<template>
  <b-modal
      id="1"
      :title="$t('OfferValidation')"
      v-model="isOpenLocal"
      size="sm"
      scrollable
      hide-footer
      @hide="tryCloseOfferModal"
  >
    <validation-observer #default="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(submitValidatedOfferValidationLocal)">

        <form-offer-validation
            :offer="offerLocal"
        />

<!--        <b-row>-->
<!--          &lt;!&ndash;          Customer request number&ndash;&gt;-->
<!--          <b-col cols="12">-->
<!--            <field-input-->
<!--                :name="'customerRequestNumber'"-->
<!--                :model.sync="offerLocal.signedDocumentNumber"-->
<!--            />-->
<!--          </b-col>-->

<!--          &lt;!&ndash;          Customer request number&ndash;&gt;-->
<!--          <b-col cols="12">-->
<!--            <field-date-->
<!--                :name="'signatureDate'"-->
<!--                :isRequired="true"-->
<!--                :model.sync="offerLocal.signedDocumentDate"-->
<!--            />-->
<!--          </b-col>-->

<!--          &lt;!&ndash;          Customer request number&ndash;&gt;-->
<!--          <b-col cols="12"-->
<!--                 class="mb-1">-->
<!--            <field-file-->
<!--                :singleFile.sync="offerLocal.signedDocument"-->
<!--                :documentType="'offer'"-->
<!--            />-->
<!--          </b-col>-->
<!--        </b-row>-->


        <button ref="submitOfferValidationFormRef"
                class="d-none"></button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'

// import FieldInput from '@/components/input/Input'
// import FieldDate from '@/components/input/Date'
// import FieldFile from '@/components/input/File'
import FormOfferValidation from '@/components/form/OfferValidation'

export default {
  components: {
    ValidationObserver,
    // FieldInput,
    // FieldDate,
    // FieldFile,
    FormOfferValidation
  },
  props: {
    offer: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const offerLocal = ref(props.offer)
    const submitOfferValidationFormRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(offerLocal, (val) => {
      emit('update:offer', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseOfferModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitOfferValidationFormRef.value.click()
      }
    }

    const submitValidatedOfferValidationLocal = () => {
      emit('submitValidatedOfferValidationForm')
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isOpenLocal,
      offerLocal,
      submitOfferValidationFormRef,

      // Computed

      // Methods
      tryCloseOfferModal,
      submitValidatedOfferValidationLocal
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    offer: function (val) {
      this.offerLocal = val
    },
    isOpen: function (val) {
      this.isOpenLocal = val
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>