<template>
  <div>

    <!--    Individual/Company-->
    <b-row class="mb-1">
      <b-col cols="12"
             class="d-flex">
        <b-form-checkbox
            v-model="contact._isCompany"
            class="custom-control-primary"
            switch
            :disabled="isDisabledEditType"
        >
          <span class="switch-icon-left">
            <icon icon="building"/>
          </span>
          <span class="switch-icon-right">
            <icon icon="user"/>
          </span>
        </b-form-checkbox>
        <span v-if="contact._isCompany">{{ capitalize($tc('company')) }}</span>
        <span v-else>{{ capitalize($tc('individual')) }}</span>
      </b-col>
    </b-row>

    <company-form
        v-if="contact._isCompany"
        :company="contact.companyEntity"
    />

    <individual-form
        v-else-if="!contact._isCompany"
        :individual="contact.userEntity"
    />


  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'
import CompanyForm from '@/components/form/company/Company'
import IndividualForm from '@/components/form/individual/Individual'

export default {
  components: {
    CompanyForm,
    IndividualForm
  },
  props: {
    contact: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isDisabledEditType = computed( () => {
      if (props.contact._isCompany == true) {
        return ('id' in props.contact.companyEntity) || ('_vSelectId' in props.contact.companyEntity)
      } else {
        return ('id' in props.contact.userEntity) || ('_vSelectId' in props.contact.userEntity)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------
console.log(props.contact)
    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      capitalize,

      isDisabledEditType
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>