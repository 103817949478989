<template>
  <div>
    <div class="d-flex justify-content-between">

      <field-select
          :name="name"
          :model.sync="contactLocal"
          :options="contactsLocal"
          :label="'_display'"
          :reduce="'_vSelectIndex'"
          :isRequired="isRequired"
          :allowAdd="true"
          :addText="'AddContact'"
          :listIndex="listIndex"
          @addCallback="selectContact(true)"
          class="w-100"
          :key="componentKey"
      >
      </field-select>

      <div class="mt-2 ml-1">
        <button-external-link
            :withBorder="false"
            @click="selectContact(false)"
            :disabled="!canEditContact"
        />
      </div>

    </div>

    <b-modal
        id="1"
        :title="('_vSelectId' in currentContact.companyEntity || '_vSelectId' in currentContact.userEntity)?$t('EditContact'):$t('NewContact')"
        v-model="contactModalShow"
        size="lg"
        scrollable
        hide-footer
        @hide="tryCloseContactModal"
    >
      <validation-observer #default="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(contactFormValidated)">
          <contact-form :contact="currentContact"/>
          <button ref="submitContactFormRef"
                  class="d-none"></button>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'
import { ValidationObserver } from 'vee-validate'

import ContactForm from '@/components/form/Contact'
import FieldSelect from '@/components/input/Select'
import useAPI from '@/utils/useAPI'
import store from '@/store'
import ButtonExternalLink from '../button/ExternalLink'

export default {
  components: {
    ContactForm,
    ValidationObserver,
    FieldSelect,
    ButtonExternalLink
  },
  props: {
    name: {
      type: String,
      default: 'contact'
    },
    contactInit: {
      type: Object,
      default: () => {}
    },
    isCompanyOnly: {
      type: Boolean,
      default: false
    },
    isIndividualOnly: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: -1
    },
    withMe: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const contactLocal = ref(null)
    const contactModalShow = ref(false)
    const submitContactFormRef = ref(null)
    const currentContact = ref({})

    const selectedContact = ref(null)
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { contacts, contactsWithMe } = useAPI()

    const contactsLocal = computed(() => {

      if (props.isCompanyOnly == true) {
        if (props.withMe) {
          return contactsWithMe.value.filter(ct => !('company' in ct))
        } else {
          return contacts.value.filter(ct => !('company' in ct))
        }
      } else if (props.isIndividualOnly) {
        if (props.withMe) {
          return contactsWithMe.value.filter(ct => ('company' in ct))
        } else {
          return contacts.value.filter(ct => ('company' in ct))
        }
      } else {
        if (props.withMe) {
          return contactsWithMe.value
        } else {
          return contacts.value
        }
      }
    })

    const canEditContact = computed(() => {
      return contactLocal.value != null
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(contactLocal, () => {
      if (contactLocal.value != null) {
        selectedContact.value = contactsLocal.value.find(contact => contact._vSelectIndex == contactLocal.value)
        selectedContact.value.id = selectedContact.value._vSelectId

        if (isCompany(selectedContact.value) == true) {
          emit('update:customerCompany', selectedContact.value)
          emit('update:customerIndividual', null)
        } else {
          emit('update:customerCompany', null)
          emit('update:customerIndividual', selectedContact.value)
        }
      } else {
        emit('update:customerCompany', null)
        emit('update:customerIndividual', null)
      }
      emit('updateAddresses')

    })

    watch(contactModalShow, () => {
      if (contactModalShow.value == false) {
        resetContact()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { getContact } = useAPI()

    const tryCloseContactModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitContactFormRef.value.click()
      }
    }

    const resetContact = () => {
      currentContact.value = JSON.parse(JSON.stringify({
        _isCompany: true,
        companyEntity: store.getters['company/getEmptyCompany'],
        userEntity: store.getters['user/getEmptyUser']
      }))
    }

    const selectContact = (isNew = false) => {
      if (isNew) {
        resetContact()
      } else {
        if (selectedContact.value != null) {
          currentContact.value = {
            _isCompany: isCompany(selectedContact.value),
            companyEntity: isCompany(selectedContact.value) ? selectedContact.value : store.getters['company/getEmptyCompany'],
            userEntity: !isCompany(selectedContact.value) ? selectedContact.value : store.getters['company/getEmptyCompany']
          }
        } else {
          resetContact()
        }
      }
      contactModalShow.value = true
    }

    const isCompany = (contact) => {
      return ('employees' in contact)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------
    onMounted(() => {
      if (props.contactInit != null) {
        let contactSelect = getContact(props.contactInit)

        if (typeof contactSelect != 'undefined') {
          let contact = JSON.parse(JSON.stringify(getContact(props.contactInit)))

          contactLocal.value = contact._vSelectIndex
          componentKey.value++
        }

      }
    })

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetContact()

    return {
      // Components
      capitalize,

      // Data
      contactLocal,
      contactModalShow,
      submitContactFormRef,
      currentContact,
      componentKey,

      // Computed
      contactsLocal,
      canEditContact,

      // Methods
      tryCloseContactModal,

      selectContact
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    contactFormValidated () {
      // console.log(this.currentContact)

      if (this.currentContact._isCompany) {// Company
        if ('id' in this.currentContact.companyEntity) {
          // Edit
          this.$store.dispatch('company/updateCompany', this.currentContact.companyEntity)
              .then(response => {
                this.$emit('update:customerCompany', response.data)
                this.$emit('updateAddresses')
                this.$nextTick(() => this.contactModalShow = false)
              })
              .catch(error => {
                console.log(error)
              })
        } else {
          // New
          this.$store.dispatch('company/addCompany', this.currentContact.companyEntity)
              .then(response => {
                this.contactLocal = (this.contactsLocal.length - 1)

                this.contactModalShow = false
                this.componentKey++
              })
              .catch(error => {
                console.log(error)
              })
        }
      } else {
        // User
        if ('id' in this.currentContact.userEntity) {
          // Edit
          this.$store.dispatch('user/updateUser', this.currentContact.userEntity)
              .then(response => {
                this.$emit('update:customerIndividual', response.data)
                this.$emit('updateAddresses')
                this.$nextTick(() => this.contactModalShow = false)
              })
              .catch(error => {
                console.log(error)
              })
        } else {
          // New
          this.$store.dispatch('user/addUser', this.currentContact.userEntity)
              .then(response => {
                this.contactLocal = (this.contactsLocal.length - 1)

                this.contactModalShow = false
                this.componentKey++
              })
              .catch(error => {
                console.log(error)
              })
        }
      }

    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>